export default class Url {
    get href() {
        return location.href;
    }

    static getParam(pKey: string) {
        const queryParams = new URLSearchParams(window.location.search);
        const paramKeyNormalized = pKey?.toLowerCase();
        const key = [...queryParams.keys()].find(k => k.toLowerCase() === paramKeyNormalized) as string;
        return queryParams.get(key);
    }

    static setParam(pKey: string, pValue: any) {
        const url = new URL(location.href);
        const params = new URLSearchParams(url.search);
        if (pValue) {
            params.set(pKey, pValue);
        } else {
            params.delete(pKey);
        }
        // let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
        url.search = params.toString();
        //window.history.pushState({path: newurl}, '', newurl);
        window.history.pushState({}, '', url);
    }

    static decodeURL(pUrl: string) {
        return decodeURIComponent(pUrl).replace(/%20/g, " ").replace(/%25/g, "%");
    }

    static decodObject(pValue: any) {
        pValue = JSON.stringify(pValue);
        pValue = encodeURIComponent(btoa(pValue));
        return pValue;
    }

    static setDecodedObject(pKey: string, pValue: any) {
        pValue = JSON.stringify(pValue);
        pValue = encodeURIComponent(btoa(pValue));

        this.setParam(pKey, pValue);
    }

    static getDecodedObject(pKey: string) {
        let value = this.getParam(pKey);
        value = atob(decodeURIComponent(value))
        try {
            return JSON.parse(value);
        } catch {
            alert("Failed to parse url params");
        }


        return null;
    }



}